import React from "react"

import * as styles from "./Timeline.module.css"
import classNames from "classnames"

import { useFirstMount } from "../../contexts/FirstMountContext"

const Timeline = () => {
  const isFirstMount = useFirstMount("Timeline")

  return (
    <div className={classNames(styles.column, {"appear-right": isFirstMount})}>
      <div className={styles.timeline}>
        <div className={styles.year}>2024</div>
        <div className={styles.itemList}>
          <div className={styles.item}>
            <a href="https://www.youtube.com/watch?v=TbNsNP9xCs8" target="_blank">Lecture</a> at the MADS School of Creativity:
            "Production and&nbsp;Direction of an Authorial Project: Turning an Idea into a Successful Case"
          </div>
          <div className={styles.item}>
            <a href="https://www.curationhour.com/featured/create" target="_blank">Interview</a> about the project "Create!" on Curation Hour
          </div>
          <div className={styles.item}>
            <a href="https://www.youtube.com/watch?v=lVfH9K0DRZ0" target="_blank">Lecture</a> at the MADS School of Creativity:
            "From Brief to&nbsp;Screen: The Algorithm for Creating Video Ads"
          </div>
          <div className={styles.item}>
            <a href="https://rasp.vidmk.ru/" target="_blank">Roundtable discussion</a>:
            "Director vs. Post-Production Producer: Challenges and Compromises" on VidMK Forum
          </div>
        </div>
        <div className={styles.year}>2023</div>
        <div className={styles.itemList}>
          <div className={styles.item}>
            <a href="https://www.youtube.com/watch?v=7fpU3LZaTGA" target="_blank">Interview</a> for the YouTube channel "Kinokorm"
          </div>
        </div>
        <div className={styles.year}>2022</div>
        <div className={styles.itemList}>
          <div className={styles.item}>
            <a href="https://adpass.ru/kinemotor-production-i-fond-tyneodna-vypustili-korotkij-metr-o-domashnem-nasilii/" target="_blank">Article</a> about the film "Come Back" on Adpass.ru
          </div>
        </div>
        <div className={styles.year}>2021</div>
        <div className={styles.itemList}>
          <div className={styles.item}>
            <a href="https://www.youtube.com/watch?v=e50f7OlkbKA" target="_blank">Master class</a> for students as part of the PRKIT 2021
          </div>
          <div className={styles.item}>
            <a href="https://www.youtube.com/watch?v=00omKRVXhAU" target="_blank">Master class</a> for students as part of the MEDIA KIT 2021
          </div>
        </div>
        <div className={styles.year}>2020</div>
        <div className={styles.itemList}>
          <div className={styles.item}>
            <a href="https://www.sostav.ru/publication/digital-lab-44037.html" target="_blank">Article</a> on Sostav.ru: "How and Why to Create Personal Projects?"
          </div>
        </div>
        <div className={styles.year}>2019</div>
        <div className={styles.itemList}>
          <div className={styles.item}>
            <a href="https://www.youtube.com/watch?v=v5uE6Vohgt0" target="_blank">Interview</a> with Ksenia Galyanova
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timeline
