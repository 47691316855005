import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

import { useFirstMount } from "../../contexts/FirstMountContext"
import * as styles from "./Bio.module.css"

const Bio = () => {
  const isFirstMount = useFirstMount("Bio")

  return (
    <div className={styles.column}>
      <div className={classNames(styles.photoWrapper, {"appear-bottom": isFirstMount})}>
        <StaticImage
          src="../../assets/images/photo.webp"
          alt="Roman Duneshenko"
          layout="fullWidth"
        />
      </div>
      <div className={classNames(styles.bio, {"appear-left": isFirstMount})}>
        <p>
          I create projects where emotions meet visual
          aesthetics - work with pace, rhythm, and feelings
          to&nbsp;ensure every story resonates with the audience.
        </p>
        <p>
          I combine commercial directing with crafting original
          work. My&nbsp;projects have been recognized with over
          150&nbsp;selections, publications, and awards worldwide.
        </p>
        <p>
          If you have an idea that calls for a bold approach,
          feel free to reach out to me.
        </p>
      </div>
    </div>
  )
}

export default Bio
