import React from "react"

import Bio from "./Bio"
import Timeline from "./Timeline"
import * as styles from "./About.module.css"

const About = () => {
  return (
    <main className={styles.about}>
      <Bio />
      <Timeline />
    </main>
  )
}

export default About
